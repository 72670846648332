import * as React from 'react';
import { useState } from 'react';
import { useStyles } from './styles';

interface TableProps {
  columns: Array<string>;
  data: Array<{ [key: string]: any }>;
  onRowClick?: (row: any) => void; // Optional row click handler
}

export const Table: React.FC<TableProps> = ({ columns, data, onRowClick }) => {
  const styles = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Handle navigation
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <div className={styles.contentBlock}>
      {/* Table */}
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentData.map((row, index) => (
            <tr
              key={index}
              className={onRowClick ? styles.clickableRow : ''}
              onClick={() => onRowClick && onRowClick(row)}
            >
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{row[column]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className={styles.pagination}>
        <div className={styles.totalRecords}>
          {`${indexOfFirstItem + 1}-${Math.min(
            indexOfLastItem,
            data.length
          )} of ${data.length}`}
        </div>

        <div className={styles.pageControls}>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={styles.arrowButton}
          >
            &lt; {/* Left arrow */}
          </button>
          <span>
            {currentPage} / {totalPages} {/* Current page / Total pages */}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={styles.arrowButton}
          >
            &gt; {/* Right arrow */}
          </button>
        </div>

        {/* Rows Per Page Selector */}
        <div className={styles.itemsPerPage}>
          <label htmlFor="itemsPerPage">Rows per page:</label>
          <select
            id="itemsPerPage"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>
    </div>
  );
};
