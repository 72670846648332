import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Loader } from '@/components';
import { Table } from '@/components/Table';
import { formatPhoneNumber } from '@/utils';
import { useStyles } from './styles';
import { debounce } from 'lodash';

export const CustomerList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { customers = [] } = location.state || {};
  const [searchQuery, setSearchQuery] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const styles = useStyles();

  // Debounce the input to prevent frequent re-renders
  const debouncedSetSearchQuery = debounce(setSearchQuery, 300);

  // Memoize the filtered results to avoid unnecessary computations
  const filteredResults = useMemo(() => {
    const searchLower = searchQuery.toLowerCase();
    return customers.filter((customer) => {
      return (
        customer.firstName.toLowerCase().includes(searchLower) ||
        customer.lastName.toLowerCase().includes(searchLower) ||
        customer.email.toLowerCase().includes(searchLower) ||
        (customer.phone && customer.phone.includes(searchQuery))
      );
    });
  }, [searchQuery, customers]);

  useEffect(() => {
    // Set a short delay to prevent layout issues on navigation
    const timeout = setTimeout(() => setIsMounted(true), 100);

    return () => clearTimeout(timeout);
  }, []);

  // Handle row click
  const handleRowClick = (customer) => {
    if (customer && customer.id) {
      navigate(`/customers/${customer.id}`);
    }
  };

  if (!isMounted || !customers.length) return <Loader />;

  return (
    <div className={styles.contentBlock}>
      <div className={styles.backButton} onClick={() => navigate(-1)}>
        <span />
      </div>
      {/* Search Container with Back Button */}
      <div className={styles.searchContainer}>
        <Typography className={styles.title} component="h2">
          Search Customer List
        </Typography>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => debouncedSetSearchQuery(e.target.value)}
          className={styles.searchInput}
        />
        <Typography component="p">
          {filteredResults.length} Match(s) found
        </Typography>
      </div>

      {/* Table */}
      <Table
        columns={['Name', 'Phone Number', 'Email']}
        data={filteredResults.map((customer) => ({
          ...customer,
          Name: `${customer.firstName} ${customer.lastName}`,
          'Phone Number': customer.phone
            ? formatPhoneNumber(customer.phone)
            : 'N/A',
          Email: customer.email
        }))}
        onRowClick={handleRowClick} // Pass row click handler
      />
    </div>
  );
};
