import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  wrapper: {
    marginTop: 64,
    overflow: 'visible',
    position: 'relative'
  },
  tableWrapper: {
    overflowY: 'auto', // Changed from 'visible'
    overflowX: 'auto', // Added horizontal scroll if needed
    maxWidth: 745,
    maxHeight: '400px',
    marginTop: 80,
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '100%'
    }
  },
  tableCell: {
    maxWidth: 190
  }
});
