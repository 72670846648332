import * as React from 'react';
import { FC, useRef, useState, useCallback, useEffect, memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getUserCountryCode } from '@/api';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  preview: {
    pointerEvents: 'none',
    '& .flag-dropdown': {
      display: 'none'
    },
    '& .form-control': {
      padding: '0!important',
      textAlign: 'center',
      border: 'none!important',
      fontSize: '25px!important'
    }
  }
});

interface PhoneInputField {
  value: string;
  handleBlur: (e: any) => void;
  setFieldValue?: (
    field: string,
    value: string,
    shouldValidate?: boolean
  ) => void;
  touched?: boolean;
  error?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  setCountryCode?: (value: { countryCode: string; dialCode: string }) => void;
  previewMode?: boolean;
}

const PhoneInputFieldComponent: FC<PhoneInputField> = ({
  value,
  handleBlur,
  touched,
  error,
  setFieldValue,
  handleChange,
  disabled,
  setCountryCode,
  previewMode
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const styles = useStyles();

  const [userCountry, setUserCountry] = useState(null);

  const onValueChange = (phoneNumber, numberData) => {
    setCountryCode({
      countryCode: numberData.countryCode,
      dialCode: numberData.dialCode
    });
    setFieldValue('phone', phoneNumber);

    if (handleChange !== null) {
      handleChange(phoneNumber);
    }
  };

  const onBlurHandler = (e) => {
    handleBlur(e);
  };

  const getCountryCode = useCallback(async () => {
    try {
      const country = await getUserCountryCode();
      if (country.length === 2) {
        setUserCountry(country);
      } else {
        setUserCountry('us'); // Set to 'us' as a fallback
      }
    } catch (error) {
      console.error('Error fetching country code:', error);
      setUserCountry('us'); // Set to 'us' as a fallback
    }
  }, []);

  useEffect(() => {
    getCountryCode();
  }, []);

  return (
    <div className="amplify-flex amplify-field amplify-textfield">
      {!previewMode && <label>Phone Number</label>}
      {!!userCountry && (
        <PhoneInput
          countryCodeEditable={false}
          ref={inputRef}
          placeholder="Enter phone number"
          inputProps={{
            name: 'phone'
          }}
          value={value}
          onChange={onValueChange}
          country={userCountry}
          onBlur={onBlurHandler}
          isInvalid={touched && error}
          disabled={disabled}
          error={error}
          className={`${touched && !!error ? 'phone-with-error' : ''} ${
            previewMode && styles.preview
          }`}
        />
      )}
      {touched && !!error && (
        <p className="amplify-text amplify-field__error-message">{error}</p>
      )}
    </div>
  );
};

export const PhoneInputField = memo(PhoneInputFieldComponent);
