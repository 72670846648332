import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative', // Ensure positioning for backButton
    margin: '0 auto',
    marginBottom: 20,
    '& p': {
      marginTop: 10,
      fontSize: 14,
      color: 'rgba(255, 255, 255, 0.8)' // Adjust color to match your theme
    }
  },
  backButton: {
    position: 'absolute',
    top: 10, // Adjust position relative to the container
    right: 25, // Keep it at the top-right corner of the container
    cursor: 'pointer',
    width: 20,
    height: 20,
    zIndex: 100,
    '& span, &:before': {
      position: 'absolute',
      top: 9,
      height: 2,
      width: '100%',
      display: 'inline-block',
      background: '#fff'
    },
    '&:before': {
      content: '""',
      transform: 'rotate(45deg)'
    },
    '& span': {
      transform: 'rotate(-45deg)'
    }
  },
  searchInput: {
    padding: '8px 12px',
    width: 370,
    borderRadius: 5,
    border: '1px solid #CACACA',
    color: '#fff',
    backgroundColor: '#2b2b2b',
    '&:focus': {
      borderColor: '#fff'
    },
    '@media (max-width: 576px)': {
      width: '100%',
      marginBottom: 10
    }
  },
  header: {
    textAlign: 'center',
    marginBottom: 40
  },
  title: {
    fontSize: 20, // Larger font size for prominence
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#fff', // Color matching the theme
    textAlign: 'center'
  },
  contentBlock: {
    textAlign: 'center',
    width: '70%', // Adjust width to fit the design
    maxWidth: 1600, // Maximum width for larger screens
    margin: '0 auto',
    padding: 20, // Padding for inner spacing
    borderRadius: 8,
    backgroundColor: '#1e1e1e', // Background color for contrast
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    maxHeight: '90vh', // Maximum height as a constraint
    minHeight: '40vh', // Set a minimum height to avoid it being too small
    height: 'auto', // Allow height to adjust automatically
    position: 'relative', // Ensure relative positioning for the backButton
    display: 'flex', // Flexbox for inner alignment
    flexDirection: 'column', // Ensure content stacks vertically
    justifyContent: 'flex-start', // Align content to the top
    alignItems: 'center', // Center content horizontally
    '@media (max-width: 576px)': {
      width: '95%' // Adjust width for smaller screens
    }
  },
  table: {
    width: '100%', // Set the table width to 100% of its container
    borderCollapse: 'collapse',
    marginTop: 20,
    '& th': {
      backgroundColor: '#333',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'left', // Set text alignment to left for column headers
      padding: '12px 20px',
      color: '#fff',
      whiteSpace: 'nowrap' // Prevent text wrapping
    },
    '& td': {
      padding: '12px 20px',
      textAlign: 'left', // Ensure text in table cells is also left-aligned
      border: '1px solid #CACACA',
      color: '#fff',
      whiteSpace: 'nowrap' // Prevent text wrapping
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#2b2b2b'
    },
    '& tr:hover': {
      backgroundColor: '#444'
    }
  }
});
