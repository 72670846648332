import * as React from 'react';
import { useStyles } from './styles';
import { Button, Typography } from '@/components';
import { Flex } from '@aws-amplify/ui-react';

interface ConfirmModalProps {
  title?: string;
  children?: React.ReactNode;
  largeText?: boolean;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  open?: boolean;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  children,
  largeText,
  handleCancel,
  handleConfirm,
  open,
  confirmText,
  cancelText
}) => {
  const styles = useStyles();

  return (
    <>
      {open && (
        <div className={styles.modalWrapper}>
          <div className={styles.content}>
            <Typography component="h2">{title}</Typography>
            <div
              className={`${styles.description} ${
                largeText && styles.largeText
              }`}
            >
              {children}
            </div>
            <Flex>
              <Button
                buttonType="primary"
                text={cancelText ? cancelText : 'No'}
                onClick={() => handleCancel()}
              />
              <Button
                buttonType="secondary"
                text={confirmText ? confirmText : 'Yes'}
                onClick={() => handleConfirm()}
              />
            </Flex>
          </div>
        </div>
      )}
    </>
  );
};
