import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
// Using HashRouter so we can get refresh correctly on AWS S3 hosting per article:  https://www.appsloveworld.com/reactjs/100/1/react-router-doesnt-work-in-aws-s3-bucket
import {
  Home,
  CreateCustomer,
  SearchCustomer,
  Customer,
  CustomerList,
  Error,
  About,
  InternetConnection,
  AttendantManagement,
  EditAttendant,
  CreateAttendant,
  EnterAttendantId
} from '@/pages';
import { Wrapper } from '@/components';
import {
  AssetsContext,
  AttendantContext
} from '@/GlobalProvider/GlobalProvider';
import { Auth } from 'aws-amplify';
import { ProtectedRoute } from '@/router/ProtectedRoute';
import { UserRoles } from '@/constants';

export const Router = () => {
  const {
    asset_file_version,
    behavior_list: {
      configurator_url,
      configurator_eventid_lookup_list,
      attendant_flow
    }
  } = useContext(AssetsContext);

  const { user } = React.useContext(AttendantContext);

  const [isCorrectConfiguration, setIsCorrectConfiguration] = useState(true);
  const [userEmail, setUserEmail] = useState(null);

  const isCorrectVersion =
    asset_file_version === process.env.REACT_APP_ASSETS_FILE_VERSION;

  const checkConfiguration = async () => {
    // if the configurator url does not have the {eventId} replacement variable then we do not need to worry about look up
    if (configurator_url.search('{eventId}') == -1) return;

    const user = await Auth.currentUserInfo();
    const email = user?.attributes.email;
    setUserEmail(email);
    const eventId = configurator_eventid_lookup_list[email];

    setIsCorrectConfiguration(!!eventId);
  };

  useEffect(() => {
    checkConfiguration();
  }, []);

  const isConfigurationCorrect = isCorrectVersion && isCorrectConfiguration;

  const getErrorRouter = () => {
    return (
      <Route
        index
        element={
          <Error
            emailConfigurationError={!isCorrectConfiguration}
            email={userEmail}
          />
        }
      />
    );
  };

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          {isConfigurationCorrect ? (
            <>
              <Route index element={<Home />} />
              <Route
                path="/createCustomer"
                element={
                  <ProtectedRoute>
                    <CreateCustomer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/createCustomer/:customerId/:accountId?"
                element={
                  <ProtectedRoute>
                    <CreateCustomer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <ProtectedRoute>
                    <SearchCustomer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customers/:customerId/:accountId?"
                element={
                  <ProtectedRoute>
                    <Customer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/customer-list"
                element={
                  <ProtectedRoute>
                    <CustomerList />
                  </ProtectedRoute>
                }
              />
            </>
          ) : (
            getErrorRouter()
          )}
          <Route path="/about" element={<About />} />
          <Route path="/internetConnection" element={<InternetConnection />} />
          {attendant_flow ? (
            <>
              {user?.role === UserRoles.Admin ? (
                <>
                  <Route
                    path="/attendantManagement"
                    element={
                      <ProtectedRoute>
                        <AttendantManagement />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/edit-attendant/:attendantId"
                    element={
                      <ProtectedRoute>
                        <EditAttendant />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/create-attendant"
                    element={
                      <ProtectedRoute>
                        <CreateAttendant />
                      </ProtectedRoute>
                    }
                  />
                </>
              ) : (
                getErrorRouter()
              )}
              <Route
                path="/enter-attendant-id"
                element={<EnterAttendantId />}
              />
            </>
          ) : (
            getErrorRouter()
          )}
        </Route>
      </Routes>
    </HashRouter>
  );
};
