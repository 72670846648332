import * as React from 'react';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Flex,
  Loader as AWSLoader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  View
} from '@aws-amplify/ui-react';
import { useStyles } from './styles';
import { getDXLRTWMeasurements } from '@/api';
import { Typography } from '@/components';
import { AlertContext } from '@/GlobalProvider/GlobalProvider';
import Select from 'rc-select';
import { IoIosArrowDown } from 'react-icons/io';
import { MdDone } from 'react-icons/md';

interface SizeRecommendationsProps {
  scanId: string;
  setFullScreen?: (v: boolean) => void;
}

export const SizeRecommendations: FC<SizeRecommendationsProps> = ({
  scanId,
  setFullScreen
}) => {
  const styles = useStyles();
  const selectRef = useRef(null);
  const { setAlert } = React.useContext(AlertContext);

  const [loading, setLoading] = useState(null);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState({});
  const [brandValue, setBrandValue] = useState([]);

  const getMeasurements = async () => {
    setLoading(true);
    try {
      const [DXLRTWMeasurements] = await Promise.all([
        getDXLRTWMeasurements(scanId)
      ]);

      setBrands(DXLRTWMeasurements.output.Brands);
    } catch (e) {
      setAlert({
        variation: 'error',
        text: React.createElement(
          'span',
          null,
          'Unable to load size recommendations for this brand'
        )
      });
    } finally {
      setLoading(false);
    }
  };

  const brandOptions = useMemo(() => {
    return brands
      ? Object.keys(brands).map((key) => ({ label: key, value: key }))
      : null;
  }, [brands]);

  const handleSelectChange = (value) => {
    if (selectRef.current) {
      selectRef.current.blur();
    }

    setBrandValue(value);
    const selected = value.reduce((result, brand) => {
      if (brands[brand]) {
        result[brand] = brands[brand];
      }
      return result;
    }, {});

    setSelectedBrands(selected);
  };

  const getUniqueKeys = (brands) => {
    const keysSet = new Set();

    Object.values(brands).forEach((brand) => {
      Object.keys(brand).forEach((key) => {
        keysSet.add(key);
      });
    });

    return Array.from(keysSet);
  };

  const prepareTableData = (selectedBrands) => {
    const uniqueKeys = getUniqueKeys(selectedBrands);
    return uniqueKeys.map((item: any) => {
      const row = { name: item };
      Object.keys(selectedBrands).forEach((brand) => {
        row[brand] = selectedBrands[brand][item] || '-';
      });
      return row;
    });
  };

  useEffect(() => {
    getMeasurements();
  }, [scanId]);

  useEffect(() => {
    setFullScreen(true);
  }, []);

  useEffect(() => {
    if (brandOptions?.length > 0) {
      const harbor = brandOptions.find((brand) => brand.label === 'Harbor Bay');
      if (harbor) {
        handleSelectChange([harbor.value]);
      }
    }
  }, [brandOptions]);

  return (
    <View className={styles.wrapper}>
      {brandOptions && (
        <>
          <label style={{ display: 'block' }}>Select Brand & Garment</label>
          <Select
            maxCount={4}
            ref={selectRef}
            showAction={['focus']}
            placeholder={'Select Brand & Garment'}
            autoFocus
            value={brandValue}
            choiceTransitionName="rc-select-selection__choice-zoom"
            mode="multiple"
            suffixIcon={<IoIosArrowDown />}
            menuItemSelectedIcon={<MdDone />}
            dropdownMatchSelectWidth={false}
            loading={loading}
            onChange={handleSelectChange}
            options={brandOptions}
            dropdownStyle={{
              zIndex: 1050,
              overflowY: 'auto',
              maxHeight: '300px', // Explicitly set max height
              width: 'auto'
            }}
            dropdownRender={(menu) => (
              <div
                style={{
                  maxHeight: 300,
                  overflowY: 'auto',
                  width: 'auto'
                }}
              >
                {menu}
              </div>
            )}
          />
        </>
      )}
      <View className={styles.tableWrapper}>
        {loading ? (
          <Flex justifyContent={'center'} alignItems={'center'}>
            <AWSLoader size="large" />
          </Flex>
        ) : Object.keys(selectedBrands).length > 0 ? (
          <Table>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 5
              }}
            >
              <TableRow>
                <TableCell
                  as="th"
                  style={{
                    background: 'rgba(1, 1, 4, 0.8)',
                    borderTopLeftRadius: '16px'
                  }}
                >
                  Name
                </TableCell>
                {Object.keys(selectedBrands).map((b, index) => (
                  <TableCell
                    key={b}
                    as="th"
                    style={{
                      background: 'rgba(1, 1, 4, 0.8)',
                      borderTopRightRadius:
                        index + 1 === Object.keys(selectedBrands).length &&
                        '16px'
                    }}
                  >
                    {b}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {prepareTableData(selectedBrands).map((row) => (
                <TableRow key={row.name}>
                  <TableCell className={styles.tableCell}>{row.name}</TableCell>
                  {Object.keys(selectedBrands).map((brand) => (
                    <TableCell className={styles.tableCell} key={brand}>
                      {row[brand]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            {/*TODO change message if need*/}
            <Typography component={'p'}>
              {brandOptions
                ? 'Please select brand to see recommendations'
                : 'Sizing recommendation not available at this time.'}
            </Typography>
          </>
        )}
      </View>
    </View>
  );
};
