import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: 0,
    marginTop: 20,
    borderRadius: 8,
    overflow: 'hidden',
    '& th': {
      backgroundColor: '#333',
      fontWeight: 'bold',
      fontSize: 20,
      color: '#fff',
      textAlign: 'left',
      padding: '12px 30px',
      borderBottom: '2px solid #CACACA',
      whiteSpace: 'nowrap'
    },
    '& td': {
      padding: '10px 30px',
      textAlign: 'left',
      color: '#fff',
      border: 'none',
      fontWeight: 'normal',
      whiteSpace: 'nowrap'
    },
    '& tr:nth-child(even)': {
      backgroundColor: '#2b2b2b'
    },
    '& tr:hover': {
      backgroundColor: '#444',
      cursor: 'pointer'
    }
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#555'
    }
  },
  contentBlock: {
    width: '95%',
    maxWidth: 1600,
    margin: '0 auto',
    padding: 25,
    borderRadius: 8,
    backgroundColor: '#1e1e1e',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
    overflowX: 'auto'
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    padding: '4px 20px',
    backgroundColor: '#2b2b2b',
    borderRadius: 8,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    '& button': {
      padding: '5px 10px',
      margin: '0 5px',
      border: 'none',
      borderRadius: 5,
      backgroundColor: '#444',
      color: '#fff',
      cursor: 'pointer',
      fontSize: 14,
      '&:hover': {
        backgroundColor: '#666'
      },
      '&:disabled': {
        cursor: 'not-allowed',
        backgroundColor: '#2b2b2b',
        color: '#777'
      }
    },
    '& span': {
      fontSize: 14,
      color: '#fff',
      margin: '0 10px'
    }
  },
  totalRecords: {
    fontSize: 14,
    color: '#fff',
    fontWeight: 'normal',
    display: 'flex',
    alignItems: 'center'
  },
  itemsPerPage: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      marginRight: 10,
      color: '#fff',
      fontSize: 14
    },
    '& select': {
      padding: '5px 10px',
      borderRadius: 5,
      border: '1px solid #CACACA',
      backgroundColor: '#2b2b2b',
      color: '#fff',
      fontSize: 14
    }
  },
  arrowButton: {
    padding: '5px 10px',
    margin: '0 5px',
    borderRadius: 5,
    backgroundColor: '#444',
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#666'
    },
    '&:disabled': {
      backgroundColor: '#2b2b2b',
      color: '#777',
      cursor: 'not-allowed'
    }
  },
  activePage: {
    backgroundColor: '#fff',
    color: '#333',
    fontWeight: 'bold',
    borderRadius: 5,
    padding: '5px 10px'
  },
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      fontSize: 14,
      color: '#fff',
      margin: '0 10px'
    }
  }
});
