import * as React from 'react';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGEX } from '@/regex';
import { Button, TextInput, PhoneInputField } from '@/components';
import { useStyles } from './styles';
import { AssetsContext } from '@/GlobalProvider/GlobalProvider';
import parsePhoneNumber from 'libphonenumber-js';
import { CustomerAccounts } from '@/constants';

const getSchema = ({ countryCode, dialCode }, errorMessage) =>
  Yup.object()
    .shape({
      email: Yup.string()
        .matches(EMAIL_REGEX, 'Enter a valid email address')
        .nullable(),
      phone: Yup.string().nullable(),
      firstName: Yup.string().nullable(),
      lastName: Yup.string().nullable()
    })
    .test(
      'at-least-one',
      'Please provide at least one contact detail: email, phone, first name, or last name',
      (values) => {
        return !!(
          values.email ||
          values.phone ||
          values.firstName ||
          values.lastName
        );
      }
    );

const initialValues = {
  email: '',
  phone: '',
  firstName: '',
  lastName: ''
};

export type SearchCustomerData = {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
};

interface SearchCustomerFormProps {
  onSubmit: (params: SearchCustomerData) => void;
  isLoading: boolean;
}
export const SearchForm: React.FC<SearchCustomerFormProps> = ({
  onSubmit,
  isLoading
}) => {
  const styles = useStyles();
  const [countryCode, setCountryCode] = useState<{
    countryCode: string;
    dialCode: string;
  }>({ countryCode: '', dialCode: '' });
  const emailInputRef = useRef<HTMLInputElement>();
  const {
    behavior_list: { customer_account_handle }
  } = useContext(AssetsContext);

  const showPhoneInput = customer_account_handle !== CustomerAccounts.EmailOnly;
  const showEmailInput = customer_account_handle !== CustomerAccounts.PhoneOnly;

  const errorMessage =
    customer_account_handle === CustomerAccounts.EmailOnly
      ? 'Email address is required'
      : customer_account_handle === CustomerAccounts.PhoneOnly
      ? 'Phone number is required'
      : customer_account_handle === CustomerAccounts.FirstNameOnly
      ? 'First name is required'
      : customer_account_handle === CustomerAccounts.LastNameOnly
      ? 'Last name is required'
      : 'Phone , email , first name, or last name is required';

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.inputMode = 'email';
    }
  }, []);

  const submitHandler = useCallback((value: any) => {
    onSubmit({
      ...value,
      phone: value.phone === countryCode.dialCode ? '' : value.phone
    });
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getSchema(countryCode, errorMessage)}
      onSubmit={submitHandler}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        errors,
        touched,
        setFieldValue
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={styles.formInputs}>
              {showEmailInput && (
                <>
                  <div className={styles.input}>
                    <TextInput
                      label="First Name"
                      type="text"
                      name="firstName"
                      placeholder="Enter first name"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={touched.firstName && !!errors.firstName}
                      errorMessage={errors.firstName as string}
                    />
                  </div>
                  <div className={styles.input}>
                    <TextInput
                      label="Last Name"
                      type="text"
                      name="lastName"
                      placeholder="Enter last name"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      hasError={touched.lastName && !!errors.lastName}
                      errorMessage={errors.lastName as string}
                    />
                  </div>
                </>
              )}
              {showPhoneInput && (
                <div className={styles.input}>
                  <PhoneInputField
                    value={values.phone}
                    error={errors.phone as string}
                    touched={touched.phone as boolean}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setCountryCode={setCountryCode}
                  />
                </div>
              )}
              {showEmailInput && (
                <div className={styles.input}>
                  <TextInput
                    ref={emailInputRef}
                    label="Email address"
                    type="email"
                    name="email"
                    placeholder="Enter email address"
                    value={values.email.toLowerCase()}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/\s/g, '');
                      handleChange(e);
                    }}
                    hasError={touched.email && !!errors.email}
                    errorMessage={errors.email as string}
                  />
                </div>
              )}
            </div>
            <div className={styles.buttonBlock}>
              <Button
                type="submit"
                text="Search a Customer"
                buttonType="secondary"
                isLoading={isLoading}
                isDisabled={isLoading}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
