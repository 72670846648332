import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  modalWrapper: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000
  },
  content: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    width: 'max-content',
    maxWidth: 850,
    minWidth: 400,
    maxHeight: '100vh',
    overflow: 'auto',
    color: '#fff',
    background: 'rgba(56, 56, 56, 0.5)',
    backdropFilter: 'blur(50px)',
    borderRadius: 30,
    padding: '20px 30px',
    textAlign: 'center',
    zIndex: 5,
    '@media (max-width: 991px)': {
      maxWidth: 640,
      borderRadius: 15,
      padding: '20px 25px'
    },
    '@media (max-width: 576px)': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: 15,
      padding: '15px'
    }
  },
  description: {
    margin: '15px 0 25px'
  },
  largeText: {
    fontSize: 28,
    fontWeight: 700
  }
}));
