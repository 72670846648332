import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  modalWrapper: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
    display: 'none'
  },
  showModal: {
    display: 'block'
  },
  content: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    width: 'max-content',
    maxWidth: 850,
    minWidth: 600,
    maxHeight: '100vh',
    overflow: 'auto',
    color: '#fff',
    background: 'rgba(56, 56, 56, 0.5)',
    backdropFilter: 'blur(50px)',
    borderRadius: 30,
    padding: '30px',
    textAlign: 'center',
    zIndex: 5,
    '@media (max-width: 991px)': {
      minWidth: 'initial',
      maxWidth: 640,
      borderRadius: 15,
      padding: '20px 25px'
    },
    '@media (max-width: 576px)': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: 15,
      padding: '15px'
    }
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    cursor: 'pointer',
    width: 20,
    height: 20,
    zIndex: 100,
    '& span, &:before': {
      position: 'absolute',
      left: 0,
      top: 9,
      height: 2,
      width: '100%',
      display: 'inline-block',
      background: theme.colors.white
    },
    '&:before': {
      content: '""',
      transform: 'rotate(45deg)'
    },
    '& span': {
      transform: 'rotate(-45deg)'
    }
  },
  description: {
    color: theme.colors.grey3
  },
  dropzoneSection: {
    position: 'relative',
    borderRadius: '16px',
    cursor: 'pointer',
    padding: '40px 15px',
    border: '10px dashed rgba(255, 255, 255, 0.1)',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '-8px',
      right: '-8px',
      left: '-8px',
      bottom: '-8px',
      background: '#353538',
      borderRadius: '16px',
      zIndex: 0
    }
  },
  reportItem: {
    background: 'rgba(0, 0, 0, 0.40)',
    padding: '0 16px',
    border: '1px solid rgba(255, 255, 255, 0.30)',
    borderRadius: '8px',
    margin: '16px 0'
  }
}));
