import * as Yup from 'yup';
import { EMAIL_REGEX } from '@/regex';
import parsePhoneNumber from 'libphonenumber-js';
import { HeightWeightUnits } from '@/constants';

export const getSchema = (
  { countryCode, dialCode },
  errorMessage,
  isEmailRequired,
  isPhoneRequired,
  isPhoneOptional,
  isAccountIdHandler,
  display_i_agree_checkbox
) =>
  Yup.object().shape(
    {
      ...(isAccountIdHandler
        ? {
            accountId: Yup.string()
              .required('Subject ID is required')
              .nullable()
          }
        : {
            email: Yup.string()
              .when('phone', {
                is: (phone) => !phone || isEmailRequired,
                then: Yup.string().required(errorMessage),
                otherwise: Yup.string()
              })
              .matches(EMAIL_REGEX, 'Enter valid email address'),
            ...(isPhoneRequired
              ? {
                  phone: Yup.string()
                    .required(errorMessage)
                    .test(
                      'isCorrectNumber',
                      `Phone number isn't valid`,
                      (val) => {
                        if (!countryCode || dialCode === val) return true;

                        const phone = parsePhoneNumber(
                          `+${val}`,
                          countryCode.toUpperCase()
                        );

                        return phone?.isValid();
                      }
                    )
                }
              : {
                  phone: Yup.string().when('email', {
                    is: (email) => !!email || isPhoneOptional,
                    then: Yup.string().test(
                      'isCorrectNumber',
                      `Phone number isn't valid`,
                      (val) => {
                        if (!countryCode || dialCode === val) return true;

                        const phone = parsePhoneNumber(
                          `+${val}`,
                          countryCode.toUpperCase()
                        );

                        return phone?.isValid();
                      }
                    ),
                    otherwise: Yup.string()
                      .required(errorMessage)
                      .test(
                        'isRequiredWithCountryCode',
                        errorMessage,
                        (val) => {
                          return dialCode !== val;
                        }
                      )
                      .test(
                        'isCorrectNumber',
                        `Phone number isn't valid`,
                        (val) => {
                          if (!countryCode) return true;

                          const phone = parsePhoneNumber(
                            `+${val}`,
                            countryCode.toUpperCase()
                          );

                          return phone?.isValid();
                        }
                      )
                  })
                }),
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required')
          }),
      dateOfBirth: Yup.number()
        .nullable()
        .required('Age is required')
        .min(18, 'You must enter an age 18 or older')
        .max(120, 'Max age 120 years'),
      feet: Yup.number().when('heightWeightUnit', {
        is: (heightWeightUnit) =>
          heightWeightUnit === HeightWeightUnits.LbsFtInches,
        then: Yup.number()
          .nullable()
          .required('Feet is required')
          .min(4, 'Min feet value is 4')
          .max(7, 'Max feet value is 7'),
        otherwise: Yup.number().nullable()
      }),
      inch: Yup.number().when('heightWeightUnit', {
        is: (heightWeightUnit) =>
          heightWeightUnit === HeightWeightUnits.LbsFtInches,
        then: Yup.number()
          .nullable()
          .required('Inch is required')
          .min(0, 'Min inch value is 0')
          .max(11, 'Max inch value is 11'),
        otherwise: Yup.number().nullable()
      }),
      height: Yup.number().when('heightWeightUnit', {
        is: (heightWeightUnit) => heightWeightUnit === HeightWeightUnits.KgCm,
        then: Yup.number()
          .nullable()
          .required('Height is required')
          .min(122, 'Height must be greater than or equal to 122 см')
          .max(242, 'Height must be less than or equal to 242 см'),
        otherwise: Yup.number().nullable()
      }),
      weight: Yup.number()
        .nullable()
        .required('Weight is required')
        .when('heightWeightUnit', {
          is: (heightWeightUnit) => heightWeightUnit === HeightWeightUnits.KgCm,
          then: Yup.number()
            .nullable()
            .min(30, 'Weight must be greater than or equal to 30 kgs')
            .max(454, 'Weight must be less than or equal to 454 kgs'),
          otherwise: Yup.number()
            .nullable()
            .min(66, 'Weight must be greater than or equal to 66 lbs')
            .max(999, 'Weight must be less than or equal to 999 lbs')
        }),
      ...(display_i_agree_checkbox
        ? { termsAndConditions: Yup.bool().oneOf([true]) }
        : {})
    },
    [
      ['phone', 'email'],
      ['email', 'phone'],
      ['height', 'heightMeasure'],
      ['weight', 'weightMeasure']
    ]
  );
