import * as React from 'react';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { BulkRegistrationModal } from '@/components/BulkRegistrationModal';

export const useStyles = createUseStyles((theme) => ({}));

export const BulkRegistrationContext = React.createContext(null);

export const BulkRegistrationProvider = ({ children }) => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);

  return (
    <BulkRegistrationContext.Provider value={{ setShowRegistrationModal }}>
      <BulkRegistrationModal
        show={showRegistrationModal}
        showHandler={() => setShowRegistrationModal(true)}
        closeHandler={() => setShowRegistrationModal(false)}
      />
      {children}
    </BulkRegistrationContext.Provider>
  );
};
