import * as React from 'react';
import { useStyles } from './styles';
import { Button, Typography } from '@/components';
import Dropzone from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import { AlertContext } from '@/GlobalProvider/GlobalProvider';
import { Flex } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { Loader as AWSLoader } from '@aws-amplify/ui-react';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';
import { MdInfoOutline } from 'react-icons/md';

import { createJob, getJob } from '@/api';

const MAX_FILE_SIZE = 1048576;
const MAX_FILES = 1;

interface BulkRegistrationModalProps {
  closeHandler: () => void;
  showHandler: () => void;
  show: boolean;
}

export const BulkRegistrationModal: React.FC<BulkRegistrationModalProps> = ({
  closeHandler,
  showHandler,
  show
}) => {
  const styles = useStyles();
  const { setAlert } = React.useContext(AlertContext);

  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [polling, setPolling] = useState(false);
  const [result, setResult] = useState(null);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if ((isUploadInProgress || polling) && !show) {
      setAlert({
        variation: 'info',
        isDismissible: false,
        text: (
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <div>Processing... Please do not reload the page!</div>
            <Button
              onClick={() => {
                showHandler();
                setAlert(null);
              }}
              style={{ width: '100px' }}
              text="View"
              size="small"
            />
          </Flex>
        )
      });
    }
  }, [show]);

  useEffect(() => {
    if (!show && result) {
      setAlert({
        variation: 'success',
        isDismissible: false,
        text: (
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <div>Job completed successfully!</div>
            <Button
              onClick={() => {
                showHandler();
                setAlert(null);
              }}
              style={{ width: '100px' }}
              text="View"
              size="small"
            />
          </Flex>
        )
      });
    }
  }, [result]);

  const handleFileUpload = async (file: File, uploadData: any) => {
    const formData = new FormData();
    // Append all fields except file
    Object.entries(uploadData.fields).forEach(([key, value]) => {
      if (key === 'Content-Type=') {
        formData.append('Content-Type', value as string);
      } else {
        formData.append(key, value as string);
      }
    });
    // Append file last
    formData.append('file', file);
    setFileName(file.name);
    // Log the full FormData (note: FormData isn't directly loggable)
    formData.forEach((value, key) => {
      console.log(`FormData entry - ${key}:`, value);
    });
    try {
      const response = await fetch(uploadData.url, {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(
          `File upload failed! Status: ${response.status}, Error: ${errorText}`
        );
      }
      return true;
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  };

  const pollJobStatus = async (jobId: string) => {
    setPolling(true);

    const interval = setInterval(async () => {
      try {
        const response = await getJob(jobId);

        if (response?.status && response.status.includes('processing')) {
          // Continue polling
          return;
        }

        // Stop polling for any other status
        clearInterval(interval);
        setPolling(false);

        if (response?.status === 'complete') {
          setResult(response);
        } else {
          setAlert({
            variation: 'warning',
            text: `Job finished with status: ${response?.status}`
          });
        }
      } catch (error) {
        console.error('Error fetching job status:', error);
        setAlert({
          variation: 'error',
          text: 'Failed to fetch job status. Stopping polling.'
        });
        clearInterval(interval);
        setPolling(false);
      }
    }, 15000);
  };

  const handleFileDrop = async (acceptedFiles: File[]) => {
    console.log('acceptedFiles', acceptedFiles);
    if (acceptedFiles.length && acceptedFiles?.length > MAX_FILES) {
      setAlert({
        variation: 'error',
        text: 'You can upload only one file'
      });
      return;
    }

    if (!acceptedFiles.length || acceptedFiles[0].size > MAX_FILE_SIZE) {
      setAlert({
        variation: 'error',
        text: 'File size exceeds the limit of 1MB!'
      });
      return;
    }

    setResult(null);
    setAlert(null);
    setIsUploadInProgress(true);

    try {
      const jobResponse = await createJob('provision-users_1'); // Create the job

      if (!jobResponse?.links?.upload) {
        throw new Error('Job creation failed!');
      }

      // Upload the CSV file
      await handleFileUpload(acceptedFiles[0], jobResponse.links.upload);

      // Start polling for job completion
      await pollJobStatus(jobResponse.id);
    } catch (error) {
      console.error('Error creating job:', error);
      setAlert({
        variation: 'error',
        text: 'Failed to create job. Please try again!'
      });
    } finally {
      setIsUploadInProgress(false);
    }
  };

  return (
    <div className={`${styles.modalWrapper} ${show && styles.showModal}`}>
      <div className={styles.content}>
        <Typography component="h2">Bulk Registration</Typography>
        <div
          className={styles.closeButton}
          onClick={() => {
            setResult(null);
            closeHandler();
          }}
        >
          <span />
        </div>
        <Typography component="h5" className={styles.description}>
          {isUploadInProgress || polling
            ? `Uploading file ${fileName}. Please do not reload the page!`
            : result
            ? 'Processing completed! Check the results below.'
            : 'Upload a CSV file to start.'}
        </Typography>
        <Dropzone
          accept={{
            'text/csv': ['.csv']
          }}
          maxSize={MAX_FILE_SIZE}
          onDrop={(acceptedFiles) => handleFileDrop(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) =>
            !isUploadInProgress && !polling && !result ? (
              <div className={styles.dropzoneSection}>
                <div
                  style={{ zIndex: 5, position: 'relative' }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <FiUpload style={{ width: '40px', height: '40px' }} />
                  <Typography component="p">
                    Drag and drop a file here, or click to select a file
                  </Typography>
                  <Typography component="h6" className={styles.description}>
                    .csv (max. file size 1MB)
                  </Typography>
                </div>
              </div>
            ) : (
              !result && <AWSLoader size="large" />
            )
          }
        </Dropzone>
        {result && (
          <>
            <Flex
              justifyContent={'space-between'}
              alignItems={'center'}
              className={styles.reportItem}
              style={{ padding: '14px' }}
            >
              <Flex alignItems={'center'}>
                <IoCheckmarkDoneCircleOutline color={'#D6F5DB'} />
                <Typography component="p">
                  Successfully created profiles
                </Typography>
              </Flex>
              <Button
                style={{ width: 'max-content' }}
                size={'small'}
                buttonType={'secondary'}
                text={'Get QR Codes'}
                onClick={() => {
                  if (!result?.links) return;
                  const qrCodeKey = Object.keys(result.links).find((key) =>
                    key.includes('qrcodes')
                  );
                  if (qrCodeKey) {
                    window.open(result.links[qrCodeKey]);
                  } else {
                    console.warn('QR code link not found!');
                  }
                }}
              />
            </Flex>
            {Object.keys(result?.links || {}).find((key) =>
              key.includes('error')
            ) && (
              <Flex
                justifyContent={'space-between'}
                alignItems={'center'}
                className={styles.reportItem}
                style={{ padding: '14px' }}
              >
                <Flex alignItems={'center'}>
                  <MdInfoOutline color={'#FE6D6D'} />
                  <Typography component="p">
                    Some profiles need review
                  </Typography>
                </Flex>
                <Button
                  style={{ width: 'max-content' }}
                  size={'small'}
                  text={'Download Report'}
                  onClick={() => {
                    if (!result?.links) return;
                    const qrCodeKey = Object.keys(result.links).find((key) =>
                      key.includes('error')
                    );
                    if (qrCodeKey) {
                      window.open(result.links[qrCodeKey]);
                    } else {
                      console.warn('QR code link not found!');
                    }
                  }}
                />
              </Flex>
            )}
          </>
        )}
      </div>
    </div>
  );
};
