import { Button } from '@/components';
import {
  AppContext,
  AssetsContext,
  AttendantContext
} from '@/GlobalProvider/GlobalProvider';
import * as React from 'react';
import { useState, useContext, FC } from 'react';
import { Auth } from 'aws-amplify';
import { useStyles } from './styles';
import * as classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VideoContext } from '@/GlobalProvider/VideoProvider';
import { signOut } from '@/api/sign-out';
import { UserRoles } from '@/constants';
import { getBrandToken } from '@/api';

export const Menu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const styles = useStyles();
  const { asset_list, behavior_list } = useContext(AssetsContext) || {};
  const { setShowVideo } = useContext(VideoContext);
  const { user } = React.useContext(AttendantContext);
  const { id } = React.useContext(AppContext);
  const { faq_url, support_url } = asset_list || {};
  const {
    prescan_video_display,
    attendant_flow,
    attendant_dashboard_url,
    configurator_url,
    enable_attendant_dashboard_access
  } = behavior_list;
  const [userEmail, setUserEmail] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);

  const navigate = useNavigate();

  const showVideo = () => {
    setShowVideo(true);
    setIsMenuVisible(false);
  };

  const getUserEmail = useCallback(async () => {
    const user = await Auth.currentUserInfo();
    const email = user?.attributes.email;

    setUserEmail(email);
  }, []);

  const getDashboardUrl = async () => {
    if (configurator_url && enable_attendant_dashboard_access === 'true') {
      const { token } = await getBrandToken(id);
      const baseUrl = configurator_url.split('/').slice(0, 3).join('/');
      token && setDashboardUrl(`${baseUrl}/attendants/${token}/dashboards`);
    }
  };

  useEffect(() => {
    getUserEmail();
    getDashboardUrl();
  }, []);

  return (
    <div
      className={classNames(styles.menu, {
        [styles.openMenu]: isMenuVisible
      })}
    >
      <div
        className={`${classNames(styles.burger, {
          [styles.openBurger]: isMenuVisible
        })} pw-indent-burger`}
        onClick={() => setIsMenuVisible(!isMenuVisible)}
      >
        <span />
      </div>
      {isMenuVisible && (
        <ul>
          {!!userEmail && (
            <li>
              <Button
                onClick={() => navigate('/about')}
                className={styles.button}
                text="About"
                size="large"
              />
            </li>
          )}
          <li>
            <Button
              onClick={() => navigate('/internetConnection')}
              className={styles.button}
              text="Internet connection"
              size="large"
            />
          </li>
          {faq_url && (
            <li>
              <Button
                onClick={() => window.open(faq_url)}
                className={styles.button}
                text="Resources"
                size="large"
              />
            </li>
          )}
          {support_url && (
            <li>
              <Button
                onClick={() => window.open(support_url)}
                className={styles.button}
                text="Support"
                size="large"
              />
            </li>
          )}
          {(prescan_video_display === 'hamburger_menu_only' ||
            prescan_video_display === 'both_locations') && (
            <li>
              <Button
                onClick={() => showVideo()}
                className={styles.button}
                text="Instruction video"
                size="large"
              />
            </li>
          )}
          {attendant_flow === 'true' && user?.role === UserRoles.Admin && (
            <li>
              <Button
                onClick={() => navigate('/attendantManagement')}
                className={styles.button}
                text="Attendants"
                size="large"
              />
            </li>
          )}
          {dashboardUrl && (
            <li>
              <Button
                onClick={() => window.open(dashboardUrl)}
                className={styles.button}
                text="Orders list"
                size="large"
              />
            </li>
          )}
          <li>
            <Button
              onClick={signOut}
              className={styles.button}
              text="Log out"
              size="large"
            />
          </li>
        </ul>
      )}
    </div>
  );
};
